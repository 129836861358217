<template>
  <div class="sp-protected">
    <div class="sp-protected-logo d-flex justify-content-center">
      <img
        :src="require('src/assets/logo/logo-vimmi-dark.svg')"
        alt="shoprz"
        width="112px"
      />
    </div>

    <error-message-component
      title="The store is not active"
      subtitle="Enter the password to access the page"
      width="490px"
      class="sp-protected-message m-auto p-0"
    >
      <template #append>
        <form @submit.prevent="handleCheckPassword">
          <div class="input-group" :class="{ 'error-input': error }">
            <label for="protected-password">Password</label>
            <div class="input-wrapper">
              <input
                id="protected-password"
                :value="password"
                :type="type"
                class="form-control rounded"
                @input="handleInputPassword"
              />

              <img
                v-if="type !== 'password'"
                :src="require('src/assets/icons/icon-eye.svg')"
                alt="eye-hide"
                @click="handleChangeType('password')"
              />

              <img
                v-else
                :src="require('src/assets/icons/icon-eye-hide.svg')"
                alt="eye-hide"
                @click="handleChangeType('text')"
              />
            </div>
            <span v-if="error" class="error-message">Wrong password</span>
          </div>
          <button
            class="btn btn-success rounded py-2 d-flex align-items-center justify-content-center"
            :class="{ disabled }"
            :disabled="disabled"
            type="submit"
          >
            Enter the Store
          </button>
        </form>
      </template>
    </error-message-component>
  </div>
</template>

<script>
  import ErrorMessageComponent from '@/shared/components/error-message.component';

  import ProtectedApi from '@/shared/api/protected.api';

  export default {
    name: 'ProtectedContainer',
    components: {
      ErrorMessageComponent,
    },
    data() {
      return {
        password: '',
        type: 'password',
        error: false,
      };
    },

    computed: {
      disabled() {
        return !this.password;
      },
    },

    mounted() {
      this.content = document.getElementById('app-content');
      this.content.classList.add('z-index');

      this.body = document.querySelector('body');
      this.body.classList.add('is-coming-soon');
    },
    beforeDestroy() {
      this.content.classList.remove('z-index');
      this.content = null;

      this.body.classList.remove('is-coming-soon');
      this.body = null;
    },

    methods: {
      async handleCheckPassword() {
        try {
          const payload = {
            sitepass: this.password,
            domain: document.location.origin.split('//')[1],
          };
          const response = await ProtectedApi.checkPassword(payload);

          if (response.verified) {
            localStorage.setItem('store-password', btoa(this.password));

            this.$router.push('/');
          }

        } catch (error) {
          console.log(error);
          this.error = true;
        }
      },

      handleInputPassword({ target }) {
        if (this.error) {
          this.error = false;
        }

        this.password = target.value;
      },

      handleChangeType(value) {
        this.type = value;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sp-protected {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    background-color: #f6f8fa;
    padding: 0 10px;
    display: flex;
    flex-direction: column;

    &-logo {
      padding-top: 40px;
    }

    &-message {
      position: relative;
      top: -82px;
    }

    .input-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      &.error-input {
        input {
          border-color: #da414a;
        }

        .error-message {
          color: #da414a;
          text-align: left;
          font-size: 12px;
          margin-top: 4px;
        }
      }

      label {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #343434;
        text-align: left;
      }

      input {
        outline: none;
        border: 1px solid #e2e6ee;
        background-color: #f6f8fa;
        height: 40px;
        padding: 15px 44px 15px 16px;
      }
    }

    .input-wrapper {
      position: relative;

      img {
        position: absolute;
        width: 12px;
        cursor: pointer;
        z-index: 2;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
      }
    }

    .btn {
      background-color: #21a66e;
      font-size: 14px;
      width: 100%;
      height: 50px;
    }
  }
</style>
