import { render, staticRenderFns } from "./protected.container.vue?vue&type=template&id=543c88cf&scoped=true"
import script from "./protected.container.vue?vue&type=script&lang=js"
export * from "./protected.container.vue?vue&type=script&lang=js"
import style0 from "./protected.container.vue?vue&type=style&index=0&id=543c88cf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "543c88cf",
  null
  
)

export default component.exports