<template>
  <protected-container/>
</template>

<script>
  import ProtectedContainer from './protected.container';

  export default {
    name: 'ProtectedView',
    components: {
      ProtectedContainer,
    },
  };
</script>
